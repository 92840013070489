// src/components/Sales/SalesCardActions.jsx

import React from 'react';

function SalesCardActions({
  onReturns,       // callback para "Devoluciones"
  onReprint,       // callback para "Reimprimir"
  onAddManual,     // callback para "Añadir Manual"
  onDiscount,      // callback para "Descuento"
  onFinalize,      // callback para "Finalizar Venta"
  disabled,        // por ejemplo, si no hay productos
}) {
  return (
    <div className="p-2 space-y-2">
      <button
        className="bg-gray-300 text-black px-2 py-2 rounded w-full hover:bg-gray-400"
        onClick={onReturns}
        disabled={disabled}
      >
        Devoluciones
      </button>

      <button
        className="bg-gray-300 text-black px-2 py-2 rounded w-full hover:bg-gray-400"
        onClick={onReprint}
        disabled={disabled}
      >
        Reimprimir
      </button>

      <button
        className="bg-green-500 text-white px-2 py-2 rounded w-full hover:bg-green-600"
        onClick={onAddManual}
        disabled={disabled}
      >
        Añadir Manual
      </button>

      <button
        className="bg-purple-500 text-white px-2 py-2 rounded w-full hover:bg-purple-600"
        onClick={onDiscount}
        disabled={disabled}
      >
        Descuento
      </button>

      <button
        className="bg-blue-600 text-white px-4 py-2 rounded w-full hover:bg-blue-700"
        onClick={onFinalize}
        disabled={disabled}
      >
        Finalizar Venta
      </button>
    </div>
  );
}

export default SalesCardActions;